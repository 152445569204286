import { makeStyles, Theme } from '@material-ui/core/styles';
import { SIDEBAR_WIDTH, TOGGLE_SIDEBAR_WIDTH } from 'common/constants/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  toggledRoot: {
    transition: '0.3s',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    height: '44px',
    background: theme.palette.surface[900],
    width: '100%',
    transition: '0.3s',
    zIndex: 300,
    position: 'fixed',
  },
  workspace: ({ sidebarOn }: { readonly sidebarOn: boolean }) => ({
    width: `${!sidebarOn ? SIDEBAR_WIDTH : TOGGLE_SIDEBAR_WIDTH}px`,
    minWidth: `${!sidebarOn ? SIDEBAR_WIDTH : TOGGLE_SIDEBAR_WIDTH}px`,
    borderRight: '1px solid rgba(255, 255, 255, 0.1)',
    paddingLeft: 27,
  }),
  toggleSidebar: {
    marginLeft: 10,
    transition: '0.3s',
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    flex: '1',
    height: '100%',
    width: '100%',
  },
  contentTabs: {
    flex: 1,
    maxWidth: 800,
  },
  openButton: {
    height: 24,
    border: 'none',
    width: 32,
    borderRadius: '4px !important',
    cursor: 'pointer',
    color: '#fff',
  },
  popover: {
    borderRadius: 3,
    minWidth: 800,
    top: '50px !important',
  },
  popoverRoot: {
    top: 50,
  },
  endIcon: {
    marginLeft: '80px',
    marginRight: 'inherit',
  },
  btn: {
    minHeight: '40px',
    cursor: 'pointer',
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '28px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  globalSearch: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 12,
    background: '#545456',
    width: '260px',
    height: '28px',
    borderRadius: 4,
    marginLeft: 12,
    color: 'rgba(255, 255, 255, 0.39)',
    position: 'relative',
    textIndent: 32,
  },
  globalSearchText: {
    color: '#8F8F91',
    fontSize: '.9rem',
  },
  searchIcon: {
    position: 'absolute',
    left: 5,
    color: '#fff',
    fill: '#fff',
  },
  notifications: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  userInfoWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  folderIcon: {
    fill: '#fff',
    color: '#fff',
  },
}));
