import { Box } from '@material-ui/core';
import React from 'react';

import LanguageSelect from './LanguageSelect';
import { Props } from './props';
import { useStyles } from './styles';
import UserInfo from './UserInfo';
import { Logo } from './Logo';

export const TopNav: React.FC<Props> = ({ sidebarOn }) => {
  const classes = useStyles({ sidebarOn });

  return (
    <div className={classes.toolbar}>
      <div className={classes.workspace}>
        <a href="https://tezapp.io">
          <Logo isMini={sidebarOn} />
        </a>
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.userInfoWrapper}>
          <Box width={56} mr={2}>
            <LanguageSelect />
          </Box>
        </div>
        <UserInfo />
      </div>
    </div>
  );
};

export default TopNav;
