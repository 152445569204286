import { RouteItem } from 'router/types/route-item';

import { AppMenuItem } from './types/app-menu-item';

export function buildMenu(list: readonly RouteItem[]): readonly AppMenuItem[] {
  return list
    .filter((l) => !l.hidden)
    .map(
      (l) =>
        ({
          name: l.name,
          path: l.path,
          items: l.items ? buildMenu(l.items) : null,
          count: 0,
          users: l.users,
        } as AppMenuItem)
    );
}
