import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: '32px 24px',
    position: 'absolute',
    maxWidth: '80vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 'none',
  },
  logo: {
    height: 30,
  },
  container: {
    textAlign: 'center',
    display: 'grid',
    gap: 24,

    '& img': {
      margin: '0 auto',
    }
  },
  h3: {
    margin: '0px 20px',
    color: '#161618',
    fontWeight: 700,
  },
  small: {
    margin: 0,
    fontWeight: 400,
    fontSize: 13,
    color: '#161618',
  },
}));
