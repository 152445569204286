import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import clsx from 'clsx';
import { SIDEBAR_WIDTH, TOGGLE_SIDEBAR_WIDTH } from 'common/constants/styles';
import React from 'react';
import { AppMenuItem } from 'store/menuSlice/types/app-menu-item';

import { MatNavProps } from './index';
import { NavItemWrapperProps, NavMenuItem } from './NavMenuItem';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: SIDEBAR_WIDTH,
      color: '#fff',
      padding: '10px 8px 0',
      transition: '0.3s',
    },
    hide: {
      minWidth: TOGGLE_SIDEBAR_WIDTH,
      padding: '10px 8px 0',
      transition: '0.3s',
    },
    text: {
      textDecoration: 'none',
    },
  })
);

export const NavMenu: React.FC<MatNavProps> = ({ items, icons, isToggle, expandSidebar }) => {
  const classes = useStyles();
  return (
    <List className={clsx(classes.root, isToggle && classes.hide)}>
      {items
        .map((item, index) => {
          const routeIcon = icons.find((i) => i.key === item.path);
          const icon = routeIcon ? routeIcon.value : <DescriptionIcon />;
          return (
            <NavMenuItem
              isToggle={isToggle}
              key={index}
              path={item.path}
              icon={icon}
              items={item.items?.map(
                (i: AppMenuItem) =>
                  ({ path: i.path, name: i.name, items: i.items } as NavItemWrapperProps)
              )}
              name={item.name}
              expandSidebar={expandSidebar}
            />
          );
        })}
    </List>
  );
};

export default NavMenu;
