import { Menu, MenuItem, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { AnchorElType } from 'common/types/AnchorElType';
import { removeCookie } from 'common/utils/cookies';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getUserInfo } from 'store/userInfoSlice';

import { useStyles } from './styles';

export const UserInfo: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { firstName } = useSelector(getUserInfo);

  const [anchorEl, setAnchorEl] = React.useState<AnchorElType>(null);

  const handleUserClick = useCallback((event: React.MouseEvent<React.ReactNode>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleRedirect = useCallback(
    (url: string) => {
      history.push(url);
      setAnchorEl(undefined);
    },
    [history]
  );

  const handleLogout = useCallback(async () => {
    try {
      removeCookie('SESSION');
      history.push('/login');
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${t('Logout error')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    }
  }, [history, enqueueSnackbar, t]);

  return (
    <div className={clsx(classes.user)}>
      <Avatar className={classes.avatar} onClick={handleUserClick} />
      <Menu
        id="user-profile-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ top: 28 }}
        disableScrollLock={true}
      >
        <MenuItem onClick={() => handleRedirect('/profile')}>{t('Profile')}</MenuItem>
        <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
      </Menu>
      <div className={classes.info}>
        <Typography variant={'body2'} className={classes.name}>
          {firstName}
        </Typography>
      </div>
    </div>
  );
};

export default UserInfo;
