import React from 'react';
import { Dialog, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { ButtonPelican } from '../../components/ButtonPelican';

export const DemoExpired: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open
      classes={{
        paper: classes.paper,
      }}
    >
      <div className={classes.container}>
        <img src="/app-name-blue.svg" alt="tezapp" className={classes.logo} />
        <img src="/assets/warning.png" alt="warning" />
        <Typography variant="h3" className={classes.h3}>
          {t('Demo version has expired!')}
        </Typography>
        <p className={classes.small}>{t('Subscribe to continue working in the application')}</p>
        <ButtonPelican style={{ width: '100%' }} variant="contained" color="primary">{t('See the plans')}</ButtonPelican>
      </div>
    </Dialog>
  );
};
