import { getCookie, removeCookie } from 'common/utils/cookies';
import { useSnackbar } from 'notistack';
import { LoginPageSkeleton } from 'pages/Login/LoginPageSkeleton';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadInitialData, setError, setLoading, getUserInfo } from 'store/userInfoSlice';

import { MainRouterSkeleton } from './MainRouterSkeleton';
import Routes, { browserHistory } from './Routes';

const MainRouter: React.FC = () => {
  const { loading, error } = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isNotLoginPage = useMemo(() => window.location?.pathname?.toLowerCase() !== '/login', []);

  const load = useCallback(async () => {
    const session = getCookie('SESSION');
    if (session && isNotLoginPage) {
      dispatch(loadInitialData());
    } else {
      dispatch(setLoading(false));
    }
  }, [dispatch, isNotLoginPage]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        `${t('Error on loading')} ${t('user info')} ${t(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          `${(error as any).response?.data?.message || error}`
        )}`,
        {
          variant: 'error',
        }
      );
      removeCookie('SESSION');
      browserHistory.push('/login');
      dispatch(setError(undefined));
    }
  }, [dispatch, enqueueSnackbar, error, t]);

  return loading ? (
    <>
      {isNotLoginPage && <MainRouterSkeleton />}
      {!isNotLoginPage && <LoginPageSkeleton />}
    </>
  ) : (
    <Routes />
  );
};

export default MainRouter;
