import { createSlice } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { SessionState } from './types/session-state';
import {  UserInfoState } from 'api/login';

const initialState: SessionState = {
  expired: false,
  loading: true,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setUserInfo(state, action: Action & { readonly payload: UserInfoState | undefined }) {
      const data = { ...state, ...action.payload };
      localStorage.setItem('userInfo', JSON.stringify(data));
      return data;
    },
    setSessionExpired(
      state,
      action: Action & {
        readonly payload: boolean;
      }
    ) {
      return {
        ...state,
        ...{ expired: action.payload },
      };
    },
    setSessionReturnUrl(
      state,
      action: Action & {
        readonly payload: string;
      }
    ) {
      return {
        ...state,
        ...{ returnUrl: action.payload },
      };
    },
    setLoading(state, action: Action & { readonly payload: boolean }) {
      return { ...state, loading: action.payload };
    },
    setError(state, action: Action & { readonly payload: unknown }) {
      return { ...state, error: action.payload };
    },
  },
});

export const { setSessionExpired, setSessionReturnUrl, setUserInfo, setLoading, setError } = sessionSlice.actions;

export const { getSessionExpired, getSessionReturnUrl, getUserInfo, getLoading, getError } = {
  getSessionExpired(state: { readonly session: SessionState }) {
    return state.session.expired;
  },
  getSessionReturnUrl(state: { readonly session: SessionState }) {
    return state.session.returnUrl;
  },
  getUserInfo(state: { readonly session: SessionState }) {
    return state.session.userInfo;
  },
  getLoading(state: { readonly session: SessionState }) {
    return state.session.loading;
  },
  getError(state: { readonly session: SessionState }) {
    return state.session.error;
  }
};

export default sessionSlice.reducer;
