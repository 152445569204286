import axios, { AxiosInstance } from 'axios';
import { browserHistory } from 'router/Routes';
import { getCookie, removeCookie } from './cookies';

const baseApiUrl = 'https://pelican-dev.alabs.space';
const service: AxiosInstance = axios.create({ baseURL: baseApiUrl, withCredentials: false });

export const AxiosInterceptor = (): void => {
  service.interceptors.request.use(
    (config) => {
      if (!config.headers?.['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }
      const session = getCookie('SESSION');
      if (session && !config.headers?.['Authorization']) {
        config.headers['Authorization'] = `Bearer ${session}`;
      }
      config.headers['Cookie'] = undefined;

      const language = localStorage.i18nextLng;
      config.headers['Accept-Language'] = language || 'en';
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  service.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error?.response?.status;
      console.log({ ...error });
      if (status && status === 401) {
        browserHistory.push('/login');
        removeCookie('SESSION');
      } else if (status && status === 403) {
        browserHistory.push('/');
      }


      return Promise.reject(error);
    }
  );
};

export default service;
