import React from 'react';

export const Logo: React.FC<{ readonly isMini?: boolean }> = ({ isMini = false }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <img
        style={{ width: 18, height: 24, marginRight: isMini ? 0 : 8 }}
        src="/logo.svg"
        alt="TezApp"
      />
      {!isMini && (
        <img style={{ width: 78, height: 20 }} src="/app-name.svg" alt="TezApp" />
      )}
    </div>
  );
};
