const mappings: { readonly [key: string]: string } = {
  Ё: 'YO',
  Й: 'I',
  Ц: 'TS',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  Ш: 'SH',
  Щ: 'SCH',
  З: 'Z',
  Х: 'H',
  Ъ: '',
  ё: 'yo',
  й: 'i',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: '',
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'a',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'ZH',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ya',
  Ч: 'CH',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: '',
  Б: 'B',
  Ю: 'YU',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: '',
  б: 'b',
  ю: 'yu',
  ' ': '_',
};

export function transliterate(word: string): string {
  return word
    .split('')
    .map(function (char) {
      return mappings[char] || char;
    })
    .join('');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeDuplicates<T extends Record<string, any>>(
  myArr: readonly T[],
  predicate: (o: T) => string | number
): readonly T[] {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(predicate).indexOf(predicate(obj)) === pos;
  });
}

export const bytesToSize = (bytes: number): string => {
  const sizes = ['B', 'Kb', 'Mb', 'Gb', 'Tb'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};
// Get hash of file from link
export const getFileHash = (link: string): string => link.toString().split('/')[5].split('_')[0];

export const getFileName = (fileName: string): string => {
  const splitted = fileName.split('.');
  splitted.splice(splitted.length - 1, 1);
  return splitted.join('.');
};

export const onlyUnique = (
  value: unknown,
  index: number,
  self: ReadonlyArray<unknown>
): boolean => {
  return self.indexOf(value) === index;
};

export const setSpaces = (num: number): string =>
  num.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

export const getCorrectTranslate = (mainWord: string, count?: number): string => {
  if (count === 1) {
    return mainWord;
  } else if (count && count % 10 === 1) {
    return `${mainWord}s(ends with 1)`;
  } else if (count && [2, 3, 4].includes(count % 10)) {
    return `${mainWord}s(ends with 2, 3, 4)`;
  } else {
    return `${mainWord}s`;
  }
};
