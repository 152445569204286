import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import useToggle from 'common/hooks/useToggle';
import { Breadcrumbs } from 'containers/Breadcrumbs';
import LoginForm from 'pages/Login/LoginForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getSessionExpired, setSessionExpired } from 'store/sessionSlice';
import { Sidebar } from 'templates/MainTemplate/Sidebar';
import TopNav from 'templates/MainTemplate/TopNav';

import { Props } from './props';
import { useStyles } from './styles';

export const MainTemplate: React.FC<Props> = ({ children, hideNav = false }) => {
  const [sidebarOn, handleSidebarOn] = useToggle(false, 'sidebarOn');
  const classes = useStyles({ sidebarOn });

  const [, handleContentSearchOn] = useToggle();
  const dispatch = useDispatch();
  const history = useHistory();
  const sessionExpired = useSelector(getSessionExpired);

  const handleSidebarToggle = (): void => {
    localStorage.setItem('sidebarOn', (!sidebarOn).toString());
    handleSidebarOn();
  };

  const handleLoginModalClose = (): void => {
    dispatch(setSessionExpired(!sessionExpired));
    history.go(0);
  };

  return (
    <section className={classes.root}>
      <TopNav
        sidebarOn={sidebarOn}
      />
      <main className={classes.main}>
        {!hideNav && (
          <nav className={clsx(classes.sidebar, sidebarOn && classes.hide)}>
            <Sidebar
              showContentNavigation={handleContentSearchOn}
              expandToggle={handleSidebarToggle}
              isToggle={sidebarOn}
            />
          </nav>
        )}
        <div
          className={clsx(!hideNav && classes.container, (sidebarOn || hideNav) && classes.stretch)}
        >
          <Breadcrumbs />
          <div className={classes.content}>{children}</div>
        </div>
      </main>
      {sessionExpired && (
        <Modal open={sessionExpired} BackdropProps={{ classes: { root: classes.loginModal } }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100vw',
            }}
          >
            <div style={{ width: 420 }}>
              <LoginForm type="sessionExpired" onModalClose={handleLoginModalClose} />
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};
