import clsx from 'clsx';
import { removeDuplicates } from 'common/utils/helpers';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { buildBreadcrumb } from 'router/helpers';
import routeList from 'router/route-list';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { Props } from './props';
import { useStyles } from './styles';

export const Breadcrumbs: React.FC<Props> = () => {
  const styles = useStyles();
  const routes = useMemo(() => buildBreadcrumb(routeList), []);
  const list = useBreadcrumbs(routes, {
    excludePaths: ['/', '/admin'],
  });
  const breadcrumbs = removeDuplicates(list, (b) => b.match.url);

  const isLastItem = (index: number): boolean => index === breadcrumbs.length - 1;

  return (
    <>
      {breadcrumbs?.length > 1 && (
        <ul className={styles.breadcrumbs}>
          {breadcrumbs.map(({ match, breadcrumb }, index) => (
            <li key={index}>
              <Link
                to={match.url}
                className={clsx(styles.breadcrumbItem, isLastItem(index) && styles.lastItem)}
              >
                {breadcrumb}
              </Link>
              {!isLastItem(index) && <span className={styles.delimiter}>/</span>}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
