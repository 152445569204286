import i18n from 'i18n';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import routeList from './route-list';
import { RouteIcon } from './types/route-icon';
import RouteItem from './types/route-item';
import { Route } from 'react-router';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../store/userInfoSlice';

export function getRouteIcons([x, ...list]: readonly RouteItem[]): readonly RouteIcon[] {
  if (x?.icon) {
    if (x?.items?.length) {
      return [
        { key: x.path, value: x.icon },
        ...getRouteIcons(x.items),
        ...(list?.length ? getRouteIcons(list) : []),
      ];
    } else {
      return [{ key: x.path, value: x.icon }, ...(list?.length ? getRouteIcons(list) : [])];
    }
  } else {
    return Array.from(list?.length ? getRouteIcons(list) : []);
  }
}

export const routeIcons = (): readonly RouteIcon[] => getRouteIcons(routeList);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function renderRouteList(
  list: readonly RouteItem[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = [];
  const { isAdmin, isCustomer } = useSelector(getUserInfo);

  list.forEach((route) => {
    if (route.items) {
      result.push(renderRouteList(route.items));
    }

    if (
      route.component &&
      route.path &&
      (!isAdmin || route.name !== 'Companies') &&
      (!isCustomer || (route.name !== 'Customers' && route.name !== 'Customer companies'))
    ) {
      result.push(
        <Route
          path={route.path}
          component={route.component}
        />
      );
    }
  });

  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildBreadcrumb(list: readonly RouteItem[]): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = [];

  list.forEach((route) => {
    if (route.items) {
      if (route.component && route.path) {
        result.push({
          path: route.path,
          routes: buildBreadcrumb(route.items),
          breadcrumb: route.breadcrumb === null ? null : route.breadcrumb || i18n.t(route.name),
        } as BreadcrumbsRoute);
      } else {
        result.push(Array.from(buildBreadcrumb(route.items)));
      }
    }

    if (route.component && route.path) {
      result.push({
        path: route.path,
        breadcrumb: route.breadcrumb === null ? null : route.breadcrumb || i18n.t(route.name),
      } as BreadcrumbsRoute);
    }
  });

  return result;
}
