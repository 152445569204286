import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { routeIcons } from 'router/helpers';
import { RouteIcon } from 'router/types/route-icon';
import { getMenuItems } from 'store/menuSlice';
import { getUserInfo } from 'store/userInfoSlice';
import MatNav from 'templates/MainTemplate/Sidebar/NavMenu';

import { ToggleMenu } from '../TopNav/ToggleMenu/ToggleMenu';
import { Props } from './props';
import { useStyles } from './styles';

export const Sidebar: React.FC<Props> = ({
  isToggle,
  expandToggle,
}) => {
  const classes = useStyles();
  const { isCustomer, isAdmin } = useSelector(getUserInfo);
  const menuItems = useSelector(getMenuItems);
  const filteredItems = menuItems.filter((item) =>
    isAdmin ?
      item.name !== 'Companies'
      : isCustomer
      ? item.name !== 'Customers'
      : true
  );

  const [iconList, setIconList] = useState<readonly RouteIcon[]>([]);

  useEffect(() => {
    setIconList(routeIcons());
  }, []);

  return (
    <div className={classes.nav}>
      <MatNav
        expandSidebar={expandToggle}
        isToggle={isToggle}
        items={filteredItems}
        icons={iconList}
      />
      <div className={classes.footer}>
        {!isToggle && (
          <div className={classes.appName}>
            <img
              src="/logo-gray.svg"
              className={classes.appLogo}
              width={18}
              height={24}
              alt="tezapp"
            />
            <Typography className={classes.appVersion}>Tezapp Admin Panel</Typography>
          </div>
        )}
        <ToggleMenu value={isToggle} toggle={expandToggle} className={classes.toggleSidebar} />
      </div>
    </div>
  );
};
