import { getCookie } from 'common/utils/cookies';
import Spin from 'components/Spin/component';
import { createBrowserHistory } from 'history';
import Login from 'pages/Login/Login';
import { DemoExpired } from 'pages/DemoExpired/DemoExpired';
import React, { Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import MaterialTemplate from 'templates/MainTemplate';
import { useSelector } from 'react-redux';

import { getUserInfo } from 'store/userInfoSlice';
import { routeList } from './route-list';
import { renderRouteList } from './helpers';

export const browserHistory = createBrowserHistory();

const Routes: React.FC = () => {
  const session = getCookie('SESSION');
  const { isAdmin, isCustomer } = useSelector(getUserInfo);
  const privateRouteList = renderRouteList(routeList);

  return (
    <Router history={browserHistory}>
      <Suspense fallback={<Spin size={60} />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/demo-expired" component={DemoExpired} />
          <Route exact path="/">
            {isAdmin && (
              <Redirect to="/customers" />
            )}
            {isCustomer && (
              <Redirect to="/companies" />
            )}
            {!isCustomer && !isAdmin && (
              <Redirect to="/login" />
            )}
          </Route>
          <Route path="/">
            {!session ? (
              <Redirect to="/login" />
            ) : (
              <MaterialTemplate>
                <Suspense fallback={<Spin size={60} />}>
                  <Switch>{privateRouteList}</Switch>
                </Suspense>
              </MaterialTemplate>
            )}
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
