import { combineReducers } from 'redux';

import sessionSlice from './sessionSlice';
import menuReducer from './menuSlice';
import userInfoReducer from './userInfoSlice';

const rootReducer = combineReducers({
  session: sessionSlice,
  menu: menuReducer,
  userInfo: userInfoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
