import request from 'common/utils/axios-request';

type LoginInput = {
  readonly username: string;
  readonly password: string;
  readonly grant_type: string;
};

type LoginResult = {
  readonly access_token: string;
  readonly expires_in: number;
};

export type UserInfoState = {
  readonly firstName?: string;
  readonly secondName?: string;
  readonly id?: number;
  readonly email?: string;
  readonly isAdmin: boolean;
  readonly isCustomer: boolean;
  readonly loading: boolean;
  readonly error?: unknown
  readonly state?: string;
};

export function loginPost(data: LoginInput): Promise<LoginResult> {
  const formData = new URLSearchParams({ ...data });
  return fetch('https://id.alabs.space/realms/TEZAPP_DEV/protocol/openid-connect/token', {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'authorization': 'Basic dGV6YXBwLXdlYjpYc0Z0Y3lWR2p2aTBhcDc0ZTBJNTdwQzFpd09SMHZldw==',
    },
  }).then((res) => res.json());
}
export function userLogout(): Promise<unknown> {
  return request('/api/v1/logout', { method: 'POST', data: {} }).then((res) => res);
}

export function customerInfoGet(): Promise<UserInfoState> {
  return request('/console/customer/me').then(({ data }) => data);
}
export function adminInfoGet(): Promise<UserInfoState> {
  return request('/console/admin/me').then(({ data }) => data);
}

export function sendEmailForResetPassword(email: string): Promise<unknown> {
  return request('/console/customer/reset/password', {
    method: 'POST',
    params: { email }
  }).then((res) => res);
}
