import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarKey, useSnackbar } from 'notistack';
import React from 'react';

export const CloseButton: React.FC<{ readonly snackKey: SnackbarKey }> = ({ snackKey }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(snackKey)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export default CloseButton;
