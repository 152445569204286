import { createSlice } from '@reduxjs/toolkit';
import { adminInfoGet, customerInfoGet, UserInfoState } from 'api/login';
import { getCookie } from 'common/utils/cookies';
import { Action } from 'redux';
import { AppThunk } from 'store/store';


const initialState: UserInfoState = {
  loading: true,
  isAdmin: false,
  isCustomer: false,
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo(state, action: Action & { readonly payload: UserInfoState | undefined }) {
      const data = { ...state, ...action.payload };
      localStorage.setItem('userInfo', JSON.stringify(data));
      return data;
    },
    logout(state) {
      localStorage.removeItem('userInfo');
      sessionStorage.clear();
      return { ...state, ...initialState, loading: false };
    },
    setLoading(state, action: Action & { readonly payload: boolean }) {
      return { ...state, loading: action.payload };
    },
    setError(state, action: Action & { readonly payload: unknown }) {
      return { ...state, error: action.payload };
    },
  },
});

export const { setUserInfo, logout, setLoading, setError } = userInfoSlice.actions;

export const { getUserInfo } = {
  getUserInfo(state: { readonly userInfo: UserInfoState }) {
    return state.userInfo;
  },
};

export const loadInitialData = (): AppThunk => async (dispatch) => {
  try {
    const token = getCookie('SESSION');
    const tokenInfo = JSON.parse(Buffer.from((token || '').split('.')[1], 'base64').toString());
    const isAdmin = tokenInfo?.realm_access?.roles?.some((r: string) => r === 'ROLE_ADMIN');
    const isCustomer = tokenInfo?.realm_access?.roles?.some((r: string) => r === 'ROLE_CUSTOMER');
    if (isAdmin) {
      const adminInfo = await adminInfoGet();
      dispatch(setUserInfo({ ...adminInfo, isAdmin: true, isCustomer: false }));
    } else if (isCustomer) {
      const customerInfo = await customerInfoGet();
      dispatch(setUserInfo({ ...customerInfo, isCustomer: true, isAdmin: false }));
    }
  } catch (e) {
    dispatch(setError(e));
  } finally {
    dispatch(setLoading(false));
  }
};

export default userInfoSlice.reducer;
