import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@material-ui/core';
import { loginPost, sendEmailForResetPassword } from 'api/login';
import { setCookie } from 'common/utils/cookies';
import { ButtonPelican } from 'components/ButtonPelican';
import { TextInput } from 'components/TextInput';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import {
  setSessionExpired,
  setSessionReturnUrl,
} from 'store/sessionSlice';
import { loadInitialData } from 'store/userInfoSlice';
import { useFormStyles } from 'theme/form-styles';

import { Props } from './props';
import { useStyles } from './styles';
import { LoginFormInput } from './types/login-form-input';
import LinkButton from '../../../components/LinkButton/LinkButton';

export const LoginForm: React.FC<Props> = ({ type = 'signIn', onModalClose }) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, errors } = useForm<LoginFormInput>({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formType, setFormType] = useState('login');

  const history = useHistory();

  const handleLogin = useCallback(
    async (data: LoginFormInput) => {
      const errors = {
        email: !data.email ? t('Email is required') : undefined,
        password: !data.password ? t('Password is required') : undefined,
      };
      if (!errors.email && !errors.password) {
        try {
          const result = await loginPost({
            username: data.email,
            password: data.password,
            grant_type: 'password',
          });
          if (result.access_token) {
            setCookie('SESSION', result.access_token);
            localStorage.setItem('sessionExpired', 'false');
            dispatch(setSessionExpired(false));
            dispatch(setSessionReturnUrl(''));
            await dispatch(loadInitialData());
            history.push('/');
          }
        } catch (e) {
          enqueueSnackbar(`${t('Login failed')}: ${e.response?.data?.message || e}`, {
            variant: 'error',
          });
        }
      }

      return errors;
    },
    [history, t, dispatch, enqueueSnackbar, type, onModalClose]
  );

  const handleReset = useCallback(async (data: LoginFormInput) => {
    const errors = {
      email: !data.email ? t('Email is required') : undefined,
    };
    if (!errors.email) {
      try {
        await sendEmailForResetPassword(data.email);
        setFormType('email-sent');
      } catch (e) {
        enqueueSnackbar(`${t('Reset password failed')}: ${e.response?.data?.message || e}`, {
          variant: 'error',
        });
      }
    }

    return errors;
  }, []);

  const login = useAsyncCallback(async (data: LoginFormInput) => {
    if (formType === 'login') {
      await handleLogin(data);
    } else if (formType === 'reset-password') {
      await handleReset(data);
    }
  });

  return (
    <div className={classes.loginContainer}>
      <header className={classes.header}>
        <img src="/app-name-blue.svg" alt="tezapp" className={classes.appLogo} />
        {formType === 'login' && (
          <Typography variant="h3" className={classes.h1}>
            {type === 'signIn' && t('Login to Your Account')}
            {type === 'sessionExpired' && t('Session expired')}
          </Typography>
        )}
        {formType === 'reset-password' && (
          <Typography variant="h3" className={classes.h1}>
            {t('Reset password')}
          </Typography>
        )}
        {formType === 'email-sent' && (
          <Typography variant="h3" className={classes.h1}>
            {t('Email sent')}
          </Typography>
        )}
      </header>
      {formType === 'login' && (
        <form onSubmit={handleSubmit(login.execute)}>
          <div className={classes.formItem}>
            <label className={formClasses.label} htmlFor="email">
              {t('Email')}
            </label>
            <div className={formClasses.input}>
              <Controller
                id="email"
                name="email"
                control={control}
                as={TextInput}
                placeholder={t('Email')}
                rules={{
                  required: true,
                }}
              />
              <div className={formClasses.error}>
                <ErrorMessage errors={errors} message={t('Email is required')} name="email" />
              </div>
            </div>
          </div>

          <div className={classes.formItem}>
            <label className={formClasses.label} htmlFor="password">
              {t('Password')}
            </label>
            <div className={formClasses.input}>
              <Controller
                id="password"
                name="password"
                control={control}
                as={TextInput}
                placeholder={t('Password')}
                type="password"
                rules={{
                  required: true,
                }}
              />
              <div className={formClasses.error}>
                <ErrorMessage errors={errors} message={t('Password is required')} name="password" />
              </div>
            </div>
          </div>
          <div className={classes.forgotPwd}>
            <LinkButton onClick={() => setFormType('reset-password')}>{t('Forgot password')}?</LinkButton>
          </div>
          <div className={classes.buttonsContainer}>
            <ButtonPelican
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              loading={login.loading}
            >
              {t('Log in')}
            </ButtonPelican>
            {type === 'sessionExpired' && (
              <ButtonPelican
                className={classes.button}
                variant="text"
                color="default"
                onClick={onModalClose}
              >
                {t('Cancel')}
              </ButtonPelican>
            )}
          </div>
        </form>
      )}
      {formType === 'reset-password' && (
        <form onSubmit={handleSubmit(login.execute)}>
          <div className={classes.formItem}>
            <label className={formClasses.label} htmlFor="email">
              {t('Email')}
            </label>
            <div className={formClasses.input}>
              <Controller
                id="email"
                name="email"
                control={control}
                as={TextInput}
                placeholder={t('Email')}
                rules={{
                  required: true,
                }}
              />
              <div className={formClasses.error}>
                <ErrorMessage errors={errors} message={t('Email is required')} name="email" />
              </div>
            </div>
          </div>
          <ButtonPelican
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            loading={login.loading}
          >
            {t('Send email')}
          </ButtonPelican>
          <div className={classes.alreadyAccount}>
            {t('Already have an account')}?
            {' '}
            <LinkButton onClick={() => setFormType('login')}>{t('Log in')}</LinkButton>
          </div>
        </form>
      )}
      {formType === 'email-sent' && (
        <div>
          <p className={classes.emailSent}>
            {t('We have sent email')}
          </p>
          <div className={classes.alreadyAccount}>
            {t('Already have an account')}?
            {' '}
            <LinkButton onClick={() => setFormType('login')}>{t('Log in')}</LinkButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
