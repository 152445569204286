import { makeStyles, Theme } from '@material-ui/core/styles';
import { TOP_NAV_HEIGHT } from 'common/constants/styles';
import React from 'react';
import LanguageSelect from 'templates/MainTemplate/TopNav/LanguageSelect';
import { Logo } from 'templates/MainTemplate/TopNav/Logo';

import LoginForm from './LoginForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
    width: '100%',
    background: '#E5E5E5',
  },
  header: {
    height: '44px',
    background: theme.palette.surface[900],
    width: `calc(100%)`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 27px',
    color: '#fff',
  },
  nav: {
    display: 'flex',
    listStyle: 'none',
    '&>li': {
      marginRight: '20px',
      color: '#545456',
    },
  },
  signUp: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Login: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <a href="https://tezapp.io">
          <Logo />
        </a>
        <div className={classes.signUp}>
          <div style={{ marginRight: '20px' }}>
            <LanguageSelect />
          </div>
          <div style={{ color: '#545456' }}>Sign up</div>
        </div>
      </header>
      <div className={classes.container}>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
