import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Spin from 'components/Spin/component';
import React from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const ButtonPelican: React.FC<Props> = ({
  variant = 'contained',
  color = 'default',
  children,
  elementRef,
  className,
  loading,
  disabled,
  size,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Button
      variant={variant || 'contained'}
      disableElevation
      ref={elementRef}
      className={clsx(
        className,
        classes.defaultStyles,
        (color === 'primary' && variant === 'contained' && classes.colorPrimary) ||
          (color === 'danger' && variant === 'contained' && classes.colorDanger) ||
          (color === 'default' && variant === 'contained' && classes.colorDefault) ||
          (color === 'success' && variant === 'contained' && classes.colorSuccess) ||
          (color === 'dark' && variant === 'contained' && classes.colorDark) ||
          (color === 'primary' && variant === 'text' && classes.variantTextColorPrimary) ||
          (color === 'danger' && variant === 'text' && classes.variantTextColorDanger) ||
          (color === 'default' && variant === 'text' && classes.variantTextColorDefault) ||
          classes.colorDefault,
        size === 'small' && classes.variantSizeSmall
      )}
      disabled={loading || disabled}
      {...rest}
    >
      <Typography
        variant="button"
        style={{
          visibility: loading ? 'hidden' : 'visible',
          lineHeight: 1,
        }}
      >
        {children}
      </Typography>
      {loading && <Spin size={20} bg="transparent" variant="absolute" />}
    </Button>
  );
};
