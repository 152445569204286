import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import React from 'react';

import RouteItem from './types/route-item';

const Companies = React.lazy(() => import('pages/Companies/Companies'));
const Customers = React.lazy(() => import('pages/Customers/Customers'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage/ProfilePage'));

export const routeList: readonly RouteItem[] = [
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
    icon: <BusinessIcon />,
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    items: [{
      path: '/customers/:id',
      name: 'Customer companies',
      component: Companies,
      hidden: true,
    }],
    icon: <PeopleIcon />,
  },
  {
    path: '/profile',
    hidden: true,
    name: 'Profile',
    component: ProfilePage,
  },
];

export default routeList;
